import { Injectable, isDevMode } from '@angular/core';

import { AuthService } from '@emrm/core/auth/service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getWindow(): any {
	return window;
}

@Injectable({
	providedIn: 'root',
})
export class GoogleAnalyticsService {
	private window;

	private ries_id: number | null = null;

	constructor(private authService: AuthService) {
		this.window = getWindow();

		if (!isDevMode()) {
			this.loadGTMScript();
		}
	}

	send(id: string) {
		if (!isDevMode()) {
			if (!this.ries_id) {
				this.ries_id = this.authService.getRiesId();
			}

			const analyticData = {
				ries_id: this.ries_id,
				event: id,
			};

			this.window.dataLayer.push(analyticData);
		}
	}

	private loadGTMScript() {
		const node = document.createElement('script');
		node.type = 'text/javascript';
		node.textContent = `
			(function (w, d, s, l, i) {
				w[l] = w[l] || [];
				w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
				var f = d.getElementsByTagName(s)[0],
					j = d.createElement(s),
					dl = l != 'dataLayer' ? '&l=' + l : '';
				j.async = true;
				j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
				f.parentNode.insertBefore(j, f);
				})(window, document, 'script', 'dataLayer', 'GTM-KWCK2894');
		`;

		document.getElementsByTagName('head')[0].appendChild(node);
	}
}
